import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container, Grid, Link, Typography } from '@mui/material';
import './statsPageTable.css';

function getStatsPageIndexTableHeadersAndRows(data, leadingPath) {
    const tableHeaders = [];
    const tableRows = [];
    let index = 0;
    for (let i = 0; i < data.length; i++) {
        tableRows.push(
            <tr key={`table-row-${i}`}>
                <td>{data[i].player.overallRank}</td>
                <td>{data[i].player.name}</td>
                <td>{data[i].player.pos}</td>
                <td>{data[i].player.team}</td>
                <td>{data[i].player.points}</td>
                <td>
                    <Link href={`${leadingPath}${data[i].path}`}>
                        View
                    </Link>
                </td>
            </tr>
        )
    }
    tableHeaders.push(
        <tr key={`table-header-row`}>
            <th>#</th>
            <th>Name</th>
            <th>Pos</th>
            <th>Team</th>
            <th>Points</th>
            <th>Stats</th>
        </tr>
    )
    return {
        header: tableHeaders,
        rows: tableRows
    }
}

export default function StatsPageIndexTable({ data, usingMediumScreen, leadingPath }) {
    const detailsHeadersAndRows = getStatsPageIndexTableHeadersAndRows(data, leadingPath);

    return (
        <table className='stats-page-index-table'>
            <thead>
                {detailsHeadersAndRows.header}
            </thead>
            <tbody>
                {detailsHeadersAndRows.rows}
            </tbody>
        </table>
    )
}
