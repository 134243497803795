// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - session reducer
export const SET_LEAGUES = '@session/SET_LEAGUES';
export const SET_ACTIVE_LEAGUE_IF_NEEDED = '@session/SET_ACTIVE_LEAGUE_IF_NEEDED';
export const SET_ACTIVE_LEAGUE = '@session/SET_ACTIVE_LEAGUE';
export const SET_ACTIVE_YEAR_WEEK = '@session/SET_ACTIVE_YEAR_WEEK';
export const SET_ACTIVE_WEEK_DATA = '@session/SET_ACTIVE_WEEK_DATA';
export const SET_ACTIVE_YEAR_WEEK_AND_DATA = '@session/SET_ACTIVE_YEAR_WEEK_AND_DATA';
export const SET_MAX_YEAR_WEEK = '@session/SET_MAX_YEAR_WEEK';
export const SET_SELECTED_YEAR_WEEK = '@session/SET_SELECTED_YEAR_WEEK';
export const SET_SELECTED_REPORT_TEAM_ID = '@session/SET_SELECTED_REPORT_TEAM_ID';
export const SET_SELECTED_LEAGUE_REPORT = '@session/SET_SELECTED_LEAGUE_REPORT';
export const SET_IS_MOBILE_DEVICE = '@session/SET_IS_MOBILE_DEVICE';
export const SET_SHOULD_HIDE_TEAM_REPORTS_MENU = '@session/SET_SHOULD_HIDE_TEAM_REPORTS_MENU';