import './statsPageTable.css';


function getStatsPageTableHeadersAndRows(data, games=null, type) {
    const headerColumns = [];
    const statRowColumns = [];
    const perGameRowColumns = [];
    let index = 0;
    headerColumns.push(
        <th key={'about'} align="center" style={theadRowTHStyle}>
            
        </th>
    );
        
    statRowColumns.push(
        <td key={`about-total`} align="center" style={tbodyRowTDStyle}>
            Total
        </td>
    );
    
    perGameRowColumns.push(
        <td key={`about-pergame`} align="center" style={tbodyRowTDStyle}>
            Avg
        </td>
    );
    if (type === 'primary') {
        for (const [category, value] of Object.entries(data)) {
            headerColumns.push(
                <th key={category} align="center" style={theadRowTHStyle}>
                    {category}
                </th>
            );
            statRowColumns.push(
                <td key={`${category}-${index}`} align="center" style={tbodyRowTDStyle}>
                    {value}
                </td>
            );
            if (games !== null) {
                perGameRowColumns.push(
                    <td key={`${category}-${index}-pergame`} align="center" style={tbodyRowTDStyle}>
                        {(value/games).toFixed(2)}
                    </td>
                );
            }
        }
    } else if (type === 'secondary') {
        for (const [category, value] of Object.entries(data)) {
            headerColumns.push(
                <th key={category} align="center" style={theadRowTHStyle}>
                    {category}
                </th>
            );
            statRowColumns.push(
                <td key={`${category}-${index}`} align="center" style={tbodyRowTDStyle}>
                    {value}
                </td>
            );
            if (games !== null) {
                perGameRowColumns.push(
                    <td key={`${category}-${index}-pergame`} align="center" style={tbodyRowTDStyle}>
                        {(value/games).toFixed(2)}
                    </td>
                );
            }
        }
    }

    return {
        headerColumns,
        statRowColumns,
        perGameRowColumns
    }
}

function getStatsPageTableHeadersAndRowsExtras(data, games=null, type) {
    const headerColumns = [];
    const allGameRowColumns = [];
    const homeGameRowColumns = [];
    const awayGameRowColumns = [];
    let index = 0;
    headerColumns.push(
        <th key={'about'} align="center" style={theadRowTHStyle}>
            
        </th>
    );
    allGameRowColumns.push(
        <td key={`about-all`} align="center" style={tbodyRowTDStyleLeft}>
            All
        </td>
    );
    homeGameRowColumns.push(
        <td key={`about-home`} align="center" style={tbodyRowTDStyleLeft}>
            Home
        </td>
    );
    awayGameRowColumns.push(
        <td key={`about-away`} align="center" style={tbodyRowTDStyleLeft}>
            Away
        </td>
    );

    for (const [category, value] of Object.entries(data.all)) {
        headerColumns.push(
            <th key={category} align="center" style={theadRowTHStyle}>
                {category}
            </th>
        );
        allGameRowColumns.push(
            <th key={`${category}-all`} align="center" style={theadRowTHStyle}>
                {value.toFixed(2)}
            </th>
        )
    }
    for (const [category, value] of Object.entries(data.home)) {
        homeGameRowColumns.push(
            <th key={`${category}-home`} align="center" style={theadRowTHStyle}>
                {value.toFixed(2)}
            </th>
        )
    }
    for (const [category, value] of Object.entries(data.away)) {
        awayGameRowColumns.push(
            <th key={`${category}-away`} align="center" style={theadRowTHStyle}>
                {value.toFixed(2)}
            </th>
        )
    }

    return {
        headerColumns,
        allGameRowColumns,
        homeGameRowColumns,
        awayGameRowColumns
    }
}

const tableStyle = {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    backgroundColor: '#fafafa',
    maxWidth: '500px'
};

const theadStyle = {
    display: 'table-header-group'
};
const theadRowStyle = {
    color: 'inherit',
    display: 'table-row',
    verticalAlign: 'middle',
    outline: 0,
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)'
}
const theadRowTHStyle = {
    letterSpacing: '0em',
    // fontWeight: 500,
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: '#212121',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    display: 'table-cell',
    verticalAlign: 'inherit',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textAlign: 'center',
    padding: '10px 0px 5px 0px',
    width: '100px'
}

const tbodyStyle = {
    display: 'table-row-group'
}
const tbodyRowStyle1 = {
    color: 'inherit',
    display: 'table-row',
    verticalAlign: 'middle',
    outline: 0,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)'
}
const tbodyRowStyle2 = {
    color: 'inherit',
    display: 'table-row',
    verticalAlign: 'middle',
    outline: 0,
    border: 'none'
}
const tbodyRowTDStyle = {
    letterSpacing: '0em',
    lineHeight: '1.5em',
    color: '#212121',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    display: 'table-cell',
    verticalAlign: 'inherit',
    textAlign: 'center',
    padding: '5px 0px 5px 0px',
    // fontWeight: 700,
    fontWeight: 'normal',
    width: '100px'
}

const tbodyRowTDStyleLeft = {
    letterSpacing: '0em',
    lineHeight: '1.5em',
    color: '#212121',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    display: 'table-cell',
    verticalAlign: 'inherit',
    textAlign: 'left',
    padding: '5px 0px 5px 20px',
    // fontWeight: 700,
    fontWeight: 'normal',
    width: '100px',
}

export default function StatsPageHeaderTable({ data, type, games, title, primaryColor, secondaryColor }) {
    let statsHeadersAndRows;
    if (type !== 'extras') {
        statsHeadersAndRows = getStatsPageTableHeadersAndRows(data, games, type);
        return (
            <table style={tableStyle}>
                <caption className="stats-page-table-caption">
                    {title}
                </caption>
                <thead style={theadStyle}>
                    <tr style={theadRowStyle}>
                        {statsHeadersAndRows.headerColumns}
                    </tr>
                </thead>
                <tbody style={tbodyStyle}>
                    <tr style={tbodyRowStyle1}>
                        {statsHeadersAndRows.statRowColumns}
                    </tr>
                    <tr style={tbodyRowStyle1}>
                        {statsHeadersAndRows.perGameRowColumns}
                    </tr>
                </tbody>
            </table>
        )
    } else {
        statsHeadersAndRows = getStatsPageTableHeadersAndRowsExtras(data, games, type);
        return (
            <table style={tableStyle}>
                <caption className="stats-page-table-caption">
                    {title}
                </caption>
                <thead style={theadStyle}>
                    <tr style={theadRowStyle}>
                        {statsHeadersAndRows.headerColumns}
                    </tr>
                </thead>
                <tbody style={tbodyStyle}>
                    <tr style={tbodyRowStyle1}>
                        {statsHeadersAndRows.allGameRowColumns}
                    </tr>
                    <tr style={tbodyRowStyle1}>
                        {statsHeadersAndRows.homeGameRowColumns}
                    </tr>
                    <tr style={tbodyRowStyle1}>
                        {statsHeadersAndRows.awayGameRowColumns}
                    </tr>
                </tbody>
            </table>
        )
    }
}
