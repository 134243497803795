import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import StatsLayout from 'layout/StatsLayout';
import { createFantasyStatsPages } from 'stats/nfl/2023/nflstats2023';

// routing
// const StatsPage = Loadable(lazy(() => import('views/pages/stats/StatsPage')));
const statsChildren = createFantasyStatsPages();
const StatsLayout = Loadable(lazy(() => import('layout/StatsLayout')));
// const statsChildren = Loadable(lazy(() => createFantasyStatsPages()));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const NFL2023Routes = {
    path: '/nfl/2023',
    element: <StatsLayout />,
    children: statsChildren
};

export default NFL2023Routes;
