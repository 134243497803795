import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// import LeagueSettingsInvitations from 'views/dashboard/League-Settings/LeagueSettingsInvitations';
// import LeagueSettingsPrivateLeagues from 'views/dashboard/League-Settings/LeagueSettingsPrivateLeagues';
// import WeeklyAllWeeksLeagueReport from 'views/dashboard/League-Report/WeeklyAllWeeksLeagueReport';
// import WeeklyTeamReport from 'views/dashboard/Team-Report/WeeklyTeamReport';
// import LeagueReportSeason from 'views/dashboard/League-Report/LeagueReportSeason';
// import LeagueReportWeek from 'views/dashboard/League-Report/LeagueReportWeek';
// import TeamReportSeason from 'views/dashboard/Team-Report/TeamReportSeason';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardHome = Loadable(lazy(() => import('views/dashboard/Home')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));


const WeeklyAllWeeksLeagueReport = Loadable(lazy(() => import('views/dashboard/League-Report/WeeklyAllWeeksLeagueReport')));
const WeeklyTeamReport = Loadable(lazy(() => import('views/dashboard/Team-Report/WeeklyTeamReport')));
const LeagueReportSeason = Loadable(lazy(() => import('views/dashboard/League-Report/LeagueReportSeason')));
const LeagueReportWeek = Loadable(lazy(() => import('views/dashboard/League-Report/LeagueReportWeek')));
const TeamReportSeason = Loadable(lazy(() => import('views/dashboard/Team-Report/TeamReportSeason')));
const LeagueSettingsInvitations = Loadable(lazy(() => import('views/dashboard/League-Settings/LeagueSettingsInvitations')));
const LeagueSettingsPrivateLeagues = Loadable(lazy(() => import('views/dashboard/League-Settings/LeagueSettingsPrivateLeagues')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/weekly/report/league/all',
            element: <WeeklyAllWeeksLeagueReport />
        },
        {
            path: '/weekly/report/manager/*',
            // element: <WeeklyTeamReport />
            element: <TeamReportSeason />
        },
        {
            path: '/league/settings/invitations',
            element: <LeagueSettingsInvitations />
        },
        {
            path: '/league/settings/missing-data',
            element: <LeagueSettingsPrivateLeagues />
        },
        {
            path: '/league/report/season',
            element: <LeagueReportSeason isSeasonReport={true} isWeeklyReport={false} />
        },
        {
            path: '/league/report/week',
            element: <LeagueReportWeek isSeasonReport={false} isWeeklyReport={true} />
        },
    ]
};

export default MainRoutes;
