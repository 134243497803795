import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useEffect, useState } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {

    const [activeLeague, setActiveLeague] = useState(null);
    const [activeYearWeek, setActiveYearWeek] = useState(null);
    const [leagueName, setLeagueName] = useState(null);
    const [activeYear, setActiveYear] = useState(null);
    const [websiteName, setWebsiteName] = useState(null);

    const theme = useTheme();

    useEffect(async () => {
        if (activeLeague === null) {
            const activeLeagueValue = JSON.parse(sessionStorage.getItem('activeLeague'));
            const activeYearWeekValue = JSON.parse(sessionStorage.getItem('activeYearWeek'));
            setWebsiteName(activeLeagueValue.website)
            setLeagueName(activeLeagueValue.name);
            setActiveYear(activeYearWeekValue.year);
            setActiveYearWeek(activeYearWeekValue);
            setActiveLeague(activeLeague);
        }
    }, [activeLeague]);


    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, filter: 'invert(1)' }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            // background: theme.palette.secondary.light,
                            // color: theme.palette.secondary.dark,
                            background: 'none',
                            color: 'white',
                            '&:hover': {
                                // background: theme.palette.secondary.dark,
                                background: '#616161',
                                // background: theme.palette.primary.light,
                                // color: theme.palette.secondary.light
                                color: 'white'
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1, ml: 5 }}>
                <Typography variant="subtitle1Light">{leagueName}</Typography>
                <Typography variant="subtitle2">{`${websiteName} | ${activeYear}`}</Typography>
            </Box>
            {/* <Box sx={{ flexGrow: 1, ml: 5, mr: 3, textAlign: 'end' }}>
                <Typography variant="subtitle2">{updateMessage}</Typography>
                <Typography variant="subtitle2">{updateDate}</Typography>
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            {/* <ProfileSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
