import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Divider, Grid, Link, Slide, Toolbar, Typography, useMediaQuery, useScrollTrigger } from '@mui/material';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'store/constant';
import { SET_LEAGUES, SET_MENU, SET_IS_MOBILE_DEVICE } from 'store/actions';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Logo from 'ui-component/Logo';
import './MainLayout.css';

// assets
// import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            // marginLeft: '20px',
            // padding: '16px'
            marginLeft: '0px',
            marginRight: '0px',
            padding: '0px 0px 0px 0px'
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            // marginLeft: '10px',
            // marginRight: '10px'
            // padding: '16px',
            marginLeft: '0px',
            marginRight: '0px',
            padding: '0px 0px 0px 0px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px'
        // }
    })
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    // const [isMobileDevice, setIsMobileDevice] = useState(false);
    // const [checkedForMobileDevice, setCheckedForMobileDevice] = useState(false);
    // let isMobileDevice = false;
    // let checkedForMobileDevice = false;
    const isMobileDevice = useSelector((state) => state.session.isMobileDevice);
    const checkedForMobileDevice = useSelector((state) => state.session.checkedForMobileDevice);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const activeLeague = JSON.parse(sessionStorage.getItem('activeLeague'));
    // const activeLeague = useSelector((state) => state.session.activeLeague);
    const leagues = useSelector((state) => state.session.leagues);

    useEffect(() => {
        if (activeLeague === null) {
            console.log('Direct user to leagues page');
            window.location.href = '/leagues';
            return;
        }

        if (leagues.length === 0) {
            const leaguesJSON = localStorage.getItem('leaguesList');
            if (leaguesJSON !== null) {
                const leaguesParsed = JSON.parse(leaguesJSON);
                dispatch({ type: SET_LEAGUES, leagues: leaguesParsed });
            }
        }
        dispatch({ type: SET_MENU, opened: !matchDownMd });

        if (!checkedForMobileDevice) {
            // eslint-disable-next-line
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
                console.log('Mobile device detected');
                dispatch({ type: SET_IS_MOBILE_DEVICE, isMobileDevice: true });
            } else {
                console.log('No mobile device detected');
                dispatch({ type: SET_IS_MOBILE_DEVICE, isMobileDevice: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, checkedForMobileDevice]);

    const mainThemeClass = isMobileDevice ? 'main-mobile' : 'main-default';

    if (activeLeague === null) {
        return null;
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            {isMobileDevice && (
                <HideOnScroll>
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            // bgcolor: theme.palette.background.default,
                            bgcolor: theme.palette.background.appBarHeader,
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        <Toolbar>
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                        </Toolbar>
                        <Divider sx={{ height: '1px', width: '100%' }} />
                    </AppBar>
                </HideOnScroll>
            )}
            {!isMobileDevice && (
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        // bgcolor: theme.palette.background.default,
                        bgcolor: theme.palette.background.appBarHeader,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                    <Divider sx={{ height: '1px', width: '100%' }} />
                </AppBar>
            )}

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} className={mainThemeClass} id="main-theme-div">
                {/* breadcrumb */}
                {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                <Outlet />
            </Main>
            {/* <Customization /> */}
        </Box>
    );
};

const headerComponentStyle = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '6px 6px 0px 0px'
};
const footerComponentStyle = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '0px 0px 6px 6px'
};

const components = {
    Header() {
        return (
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={headerComponentStyle}>
                <Grid item sx={{ mb: 3, mt: 3 }}>
                    <Link to="/features" href="/features">
                        <Logo />
                    </Link>
                </Grid>
            </Grid>
        );
    },
    Footer() {
        return (
            <Grid container className="footer-layout" spacing={0} alignItems="center" justifyContent="center" style={footerComponentStyle}>
                <Grid item sx={{ mt: 2, mb: 3, textAlign: 'center' }}>
                    <Typography variant="subtitle2">© 2024 FantasyUpgrade.com</Typography>
                </Grid>
            </Grid>
        );
    }
};

export default withAuthenticator(MainLayout, {
    components
});
// export default withAuthenticator(MainLayout);
