import LeaguesLayout from 'layout/LeaguesLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// routing
// const LeaguesPage = Loadable(lazy(() => import('views/pages/landing/home')));

// import LeaguesPage from 'views/pages/landing/leagues';
// import InvitationPage from 'views/pages/landing/invitation';
// import ConfirmationPage from 'views/pages/landing/confirmation/ConfirmationPage';
// import PaymentFailurePage from 'views/pages/landing/confirmation/PaymentFailurePage';
// import InvalidCountryPage from 'views/pages/landing/confirmation/InvalidCountryPage';

const LeaguesPage = Loadable(lazy(() => import('views/pages/landing/leagues')));
const InvitationPage = Loadable(lazy(() => import('views/pages/landing/invitation')));
const ConfirmationPage = Loadable(lazy(() => import('views/pages/landing/confirmation/ConfirmationPage')));
const PaymentFailurePage = Loadable(lazy(() => import('views/pages/landing/confirmation/PaymentFailurePage')));
const InvalidCountryPage = Loadable(lazy(() => import('views/pages/landing/confirmation/InvalidCountryPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LeagueRoutes = {
    path: '/',
    element: <LeaguesLayout />,
    children: [
        {
            path: '/leagues',
            element: <LeaguesPage />
        },
        {
            path: '/invitation',
            element: <InvitationPage />
        },
        {
            path: '/invitation/*',
            element: <InvitationPage />
        },
        {
            path: '/payment/confirmation',
            element: <ConfirmationPage />
        },
        {
            path: '/payment/failure',
            element: <PaymentFailurePage />
        },
        {
            path: '/checkout-failed/invalid-country',
            element: <InvalidCountryPage />
        }
    ]
};

export default LeagueRoutes;
