// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Divider, Grid, Link, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project imports
import LandingHeader from '../LandingLayout/Header';

// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

// import Customization from '../Customization';
// import navigation from 'menu-items';
// import { drawerWidth, gridSpacing } from 'store/constant';
// import { SET_MENU } from 'store/actions';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Logo from 'ui-component/Logo';

// assets
// import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(true && {
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 0,
        marginTop: 70
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const LeaguesLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    // bgcolor: theme.palette.background.default
                    bgcolor: theme.palette.background.appBarHeader,
                }}
            >
                <Toolbar>
                    <LandingHeader />
                </Toolbar>
                <Divider sx={{ height: '1px', width: '100%' }} />
            </AppBar>

            {/* main content */}
            {/* <Outlet /> */}
            <Grid container sx={{ marginTop: '80px' }}>
                <Grid item xs={12}>
                    <Outlet />
                    <Divider sx={{ height: '1px', width: '100%' }} />
                </Grid>
                <Grid item xs={12} className="footer-layout" sx={{ backgroundColor: theme.palette.dark.dark, p: 3, color: 'white' }}>
                    <Typography variant="p">© 2024 FantasyUpgrade.com</Typography>
                    <Stack direction="row" spacing={2} sx={{ float: 'right' }}>
                        <Link href="/legal/privacy-policy" sx={{ float: 'right' }}>
                            Privacy Policy
                        </Link>
                        <Link href="/legal/terms-of-use" sx={{ float: 'right' }}>
                            Terms of Use
                        </Link>
                    </Stack>
                </Grid>
                <Divider sx={{ height: '1px', width: '100%' }} />
            </Grid>
        </Box>
    );
};

const headerComponentStyle = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '6px 6px 0px 0px'
};
const footerComponentStyle = {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: '0px 0px 6px 6px'
};

const components = {
    Header() {
        return (
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={headerComponentStyle}>
                <Grid item sx={{ mb: 3, mt: 3 }}>
                    <Link to="/features" href="/features">
                        <Logo />
                    </Link>
                </Grid>
            </Grid>
        );
    },
    Footer() {
        return (
            <Grid container spacing={0} alignItems="center" justifyContent="center" style={footerComponentStyle}>
                <Grid item sx={{ mt: 2, mb: 3, textAlign: 'center' }}>
                    <Typography variant="subtitle2">© 2024 FantasyUpgrade.com</Typography>
                </Grid>
            </Grid>
        );
    }
};

export default withAuthenticator(LeaguesLayout, {
    components
});
// export default withAuthenticator(LeaguesLayout);
