import { lazy, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// material-ui
import { Grid, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';

// project imports
// import Loadable from 'ui-component/Loadable';

import './statsPage.css';
import StatsPageWrapper from './StatsPageWrapper';

import nflColors from '../../../assets/scss/_colors_nfl_teams-vars.module.scss';
import { convertNFLTeamToColorKey } from './utility/statsPageUtility';

import qbIcon from 'assets/images/icons/awards/qb.webp';
import rbIcon from 'assets/images/icons/awards/rb.png';
import wrIcon from 'assets/images/icons/awards/wr-2.png';
import teIcon from 'assets/images/icons/awards/te.png';
import StatsPageHeaderTable from './tables/statsPageHeaderTable';
import { useTheme } from '@mui/styles';

// const StatsPointsByGameChart = lazy(() => import('./charts/StatsPointsByGameChart'));
import StatsPointsByGameChart from './charts/StatsPointsByGameChart';
import useIsVisible from 'views/utilities/useIsVisible';

const positionIcons = {
    QB: qbIcon,
    RB: rbIcon,
    WR: wrIcon,
    TE: teIcon
};

const h1ComponentStyle = {
    fontWeight: 'normal',
    fontSize: '20px',
    color: '#212121',
    margin: 0,
    textAlign: 'center'
};

const h2ComponentStyle = {
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#212121',
    margin: 0,
    textAlign: 'center'
};

const iconSubtextStyle = {
    fontSize: '14px',
    color: '#212121',
    margin: 0
}

const StatsPage = ({data, extension}) => {
    // console.log(data);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [playerData, setPlayerData] = useState(null);
    const [chartDiv, setChartDiv] = useState(null);

    const teamColorKey = convertNFLTeamToColorKey(data.team);
    const teamColor = nflColors[teamColorKey[0]];
    const secondaryColor = nflColors[teamColorKey[1]]

    const theme = useTheme();

    const usingMediumScreen = !useMediaQuery(theme.breakpoints.up('md'));
    const gridItemClassName = usingMediumScreen ? 'mobile-stats-page-grid' : 'stats-page-grid';
    // console.log(gridItemClassName);

    // const elemRef = useRef();
    // const isVisible = useIsVisible(elemRef);
    // console.log('isVisible: ', isVisible);

    const h2SeasonStatsStyle = {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: '#212121',
        margin: 0,
        textAlign: 'center',
        backgroundColor: teamColor,
        color: '#f2f2f2',
        outline: `3px solid ${secondaryColor}`,
        padding: '10px 0px 10px 0px',
    };

    useEffect(() => {

        const loadData = (async () => {
            console.log('loading player data...');
            const tempData = await import(`stats/nfl/2023/player-results${extension}.json`);
            setPlayerData(tempData.player);
            setDataLoaded(true);
        });

        if (!dataLoaded) {
            loadData();
        }
    }, [dataLoaded]);

    // console.log(data);
    if (playerData) {
        console.log(playerData);
        // console.log(playerData.player);
    } else {
        console.log('Player data not loaded');
        return (
            <div></div>
        )
    }

    const metaDescription = `${playerData.name} scored ${playerData.stats.details.Points} fantasy points at ${playerData.pos} in 2023 with an average of ${playerData.stats.details.PPG} ppg. He was the #${playerData.overallRank} ranked fantasy player in the NFL, the #${playerData.positionRank} ranked ${playerData.pos} overall, and the #${playerData.teamRank} ranked player on the ${playerData.team}.`;
    const metaKeyWords = `${playerData.name}, ${playerData.pos}, ${playerData.team}, NFL, Fantasy, Fantasy Points, stats`;
    


    return (
        <StatsPageWrapper>
            <HelmetProvider>
                <Helmet>
                    <title>{`${data.name} Fantasy Stats 2023`}</title>
                    <meta name="title" content={`${data.name} Fantasy Stats 2023`} />
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={metaKeyWords} />
                </Helmet>
            </HelmetProvider>
            <Grid item xs={12} sx={{ minHeight: '50px', backgroundColor: '#fafafa', px: 3, pb: 3 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            sx={{
                                maxWidth: '80px', maxHeight: '80px', width: '80px', height: '80px',
                                display: 'flex', justifyContent: 'center'
                            }}
                        >
                            <img src={positionIcons[data.pos]} alt={`${data.pos}-icon`} width="92" height="92" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <h1 style={h1ComponentStyle}>
                            <b>{data.name}</b> {playerData.h1}
                        </h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={h2ComponentStyle}>
                            {/* {data.stats.details.PPG} ppg */}
                            {data.points} ppg
                        </h2>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={h2ComponentStyle}>
                            {playerData.h2a}
                        </h2>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={h2ComponentStyle}>
                            {playerData.h2b}
                        </h2>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction="column" className={gridItemClassName} spacing={3} sx={{ backgroundColor: '#fafafa' }}>
                <Grid item xs={12}>
                    <h2 style={h2SeasonStatsStyle}>Season Stats</h2>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <StatsPageHeaderTable
                        data={playerData.stats.primary} type="primary" games={playerData.stats.details.Games} title="Primary"
                        primaryColor={teamColor} secondaryColor={secondaryColor}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <StatsPageHeaderTable
                        data={playerData.stats.secondary} type="secondary" games={playerData.stats.details.Games} title="Secondary"
                        primaryColor={teamColor} secondaryColor={secondaryColor}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', pb: 3 }}>
                    <StatsPageHeaderTable
                        data={playerData.extras} type="extras" games={playerData.stats.details.Games} title="Game Scores"
                        primaryColor={teamColor} secondaryColor={secondaryColor}
                    />
                </Grid>
                <StatsPointsByGameChart
                    data={playerData.games}
                    teamColor={teamColor}
                    secondaryColor={secondaryColor}
                />
                {/* <Grid item xs={12} sx={{ px: 0, py: 5, backgroundColor: '#fafafa', minHeight: '525px', minWidth: '100%' }} ref={elemRef}>
                    <div style={{ display: (isVisible ? 'block' : 'block') }}>
                        <StatsPointsByGameChart
                            data={playerData.games}
                            teamColor={teamColor}
                            secondaryColor={secondaryColor}
                        />
                    </div>
                </Grid> */}
            </Grid>
        </StatsPageWrapper>
    )
};

export default StatsPage;
