import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LandingRoutes from './LandingRoutes';
import LeagueRoutes from './LeagueRoutes';
import HomeRoutes from './HomeRoutes';
import DemoRoutes from './DemoRoutes';
import config from 'config';
import NFL2023Routes from './NFL/2023/NFL2023Routes';
// const NFL2023Routes = lazy(() => import('./NFL/2023/NFL2023Routes'));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes(
        [
            HomeRoutes, MainRoutes, AuthenticationRoutes, LandingRoutes, LeagueRoutes, DemoRoutes,
            NFL2023Routes
        ],
        config.basename
    );
}
