// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
// import menuItems, { getDashboardItems } from 'menu-items';
import { useSelector } from 'react-redux';
import { getTeamReportMenuItems } from 'menu-items/dashboard';
import { useEffect, useState } from 'react';

import {
    IconDashboard, IconNotes, IconChartBar, IconChartDots, IconStars,
    IconListNumbers, IconReportAnalytics, IconSettings, IconCalendar,
    IconHistory, IconKey, IconMailForward, IconUsers, IconHelp, IconInfoCircle
} from '@tabler/icons';
const icons = {
    IconDashboard, IconReportAnalytics, IconNotes, IconChartBar,
    IconChartDots, IconStars, IconListNumbers, IconSettings,
    IconCalendar, IconHistory, IconKey, IconMailForward,
    IconUsers, IconHelp, IconInfoCircle
};

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [managersForMenuItems, setManagersForMenuItems] = useState(null);
    const [menuItemsSet, setMenuItemsSet] = useState(false);
    const [navItemsForMenu, setNavItemsForMenu] = useState(null);
    const [updateAttempts, setUpdateAttempts] = useState(0);
    const [selectedSeason, setSelectedSeason] = useState(null);

    // console.log(menuItems);
    
    const sessionActiveYearWeek = JSON.parse(sessionStorage.getItem('activeYearWeek'));
    const sessionActiveYear = sessionActiveYearWeek.year;
    const selectedLeagueReport = useSelector((state) => state.session.selectedLeagueReport);
    console.log(selectedLeagueReport);

    useEffect(() => {
        if (!menuItemsSet) {
            const managersByTeamID = JSON.parse(sessionStorage.getItem('selectedLeagueManagers'));

            const league = JSON.parse(sessionStorage.getItem('activeLeague'));
            const seasons = league.seasons.items;
            const isCommissioner = league.isCommissioner;
            const leagueID = league.id;
            const isDemo = leagueID === 'demo-league';

            const activeYearWeek = JSON.parse(sessionStorage.getItem('activeYearWeek'));
            const activeYear = activeYearWeek.year;
            // console.log(activeYear);
            setSelectedSeason(activeYear);

            // if managersByTeamID is null, wait 1 second for it to be set
            if (!managersByTeamID) {
                console.log('useEffect: ', updateAttempts);
                if (updateAttempts > 0 && selectedLeagueReport) {
                    const teamIDs = Object.keys(selectedLeagueReport.reportsByTeamID);
                    const managers = {};
                    for (let i = 0; i < teamIDs.length; i++) {
                        let selectedRegularSeasonReport = selectedLeagueReport.reportsByTeamID[teamIDs[i]].regularSeasonReport;
                        if (selectedRegularSeasonReport.manager !== 'null') {
                            managers[teamIDs[i]] = selectedLeagueReport.reportsByTeamID[teamIDs[i]].regularSeasonReport.manager;
                        } else {
                            managers[teamIDs[i]] = selectedLeagueReport.teamName;
                        }
                    }
                    console.log(managers);
                    sessionStorage.setItem('selectedLeagueManagers', JSON.stringify(managers));
                }
                if (updateAttempts < 20) {
                    setTimeout(() => {
                        setUpdateAttempts(updateAttempts + 1);
                    }, 1000);
                } else {
                    sessionStorage.setItem('selectedLeagueManagers', JSON.stringify({}));
                }
            } else {
                console.log(managersByTeamID);
            
                setManagersForMenuItems(managersByTeamID);
                setMenuItemsSet(true);

                const teamReportMenuItems = [];
                const managerSelectMenuItems = [];
                // eslint-disable-next-line
                for (const [teamID, manager] of Object.entries(managersByTeamID)) {
                    teamReportMenuItems.push({
                        id: `team-report-${teamID}`,
                        title: manager,
                        type: 'item',
                        url: `/dashboard/season/report/team/${teamID}`,
                        breadcrumbs: false
                    });
                    if (!isDemo) {
                        managerSelectMenuItems.push({
                            id: `manager-weekly-report-${teamID}`,
                            title: manager,
                            type: 'item',
                            url: `/dashboard/weekly/report/manager/${teamID}`,
                            breadcrumbs: false
                        });
                    } else if (isDemo) {
                        managerSelectMenuItems.push({
                            id: `manager-weekly-report-${teamID}`,
                            title: manager,
                            type: 'item',
                            // url: `/dashboard/demo/weekly/report/manager/${teamID}`,
                            url: `/dashboard/demo/weekly/report/manager/${teamID}`,
                            breadcrumbs: false
                        });
                    }
                }


                const leagueMenuItemsSub = [
                    {
                        id: 'leagueSettings',
                        title: 'Send Invitations',
                        type: 'item',
                        url: '/dashboard/league/settings/invitations',
                        icon: icons.IconMailForward,
                        breadcrumbs: false
                    },
                    {
                        id: 'missingData',
                        title: 'Missing Data',
                        type: 'item',
                        url: '/dashboard/league/settings/missing-data',
                        icon: icons.IconInfoCircle,
                        breadcrumbs: false
                    }
                ];
                const leagueMenu = {
                    id: 'league',
                    title: 'League',
                    caption: 'Settings and Management',
                    type: 'group',
                    children: leagueMenuItemsSub
                }

                // if (!isDemo) {
                //     managerSelectMenuItems.unshift({
                //         id: `league-manager-all`,
                //         title: 'All Managers',
                //         type: 'item',
                //         url: '/dashboard/weekly/report/league/all',
                //         breadcrumbs: false,
                //         selected: true
                //     });
                // } else if (isDemo) {
                //     managerSelectMenuItems.unshift({
                //         id: `league-manager-all`,
                //         title: 'All Managers',
                //         type: 'item',
                //         url: '/dashboard/demo/weekly/report/league/all',
                //         breadcrumbs: false,
                //         selected: true
                //     });
                // }

                let leagueReportSelectMenuItems = [];
                if (!isDemo) {
                    leagueReportSelectMenuItems.push({
                        id: `league-season-report`,
                        title: 'Season Report',
                        type: 'item',
                        url: `/dashboard/league/report/season`,
                        breadcrumbs: false
                    });
                    leagueReportSelectMenuItems.push({
                        id: `league-weekly-reports`,
                        title: 'Weekly Reports',
                        type: 'item',
                        url: `/dashboard/league/report/week`,
                        breadcrumbs: false
                    });
                } else if (isDemo) {
                    leagueReportSelectMenuItems.push({
                        id: `league-season-report`,
                        title: 'Season Report',
                        type: 'item',
                        url: `/dashboard/demo/league/report/season`,
                        breadcrumbs: false
                    });
                    leagueReportSelectMenuItems.push({
                        id: `league-weekly-reports`,
                        title: 'Weekly Reports',
                        type: 'item',
                        url: `/dashboard/demo/league/report/week`,
                        breadcrumbs: false
                    });
                }

                const weekSelectMenu = {
                    id: 'weekSelect',
                    title: 'Reports',
                    caption: 'Season and Weekly Reports',
                    type: 'group',
                    children: [
                        {
                            id: 'league-reports',
                            title: 'League',
                            type: 'collapse',
                            icon: icons.IconCalendar,
                            breadcrumbs: false,
                            children: leagueReportSelectMenuItems
                        },
                        {
                            id: 'Managers',
                            title: 'Managers',
                            type: 'collapse',
                            icon: icons.IconUsers,
                            breadcrumbs: false,
                            children: managerSelectMenuItems
                        },
                    ]
                }

                // const menuItems = [weekSelectMenu, seasonSelectMenu, dashboard, leagueMenu];
                let menuItems = [];
                if (isCommissioner) {
                    console.log('SHOW settings');
                    menuItems = [weekSelectMenu, leagueMenu];
                } else if (!isCommissioner) {
                    console.log('DONT show settings');
                    menuItems = [weekSelectMenu];
                }
                // const menuItems = [weekSelectMenu, leagueMenu];
                const navItems = menuItems.map((item) => {
                    switch (item.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                });
                setNavItemsForMenu(navItems);
            }
        } else if (selectedSeason !== null && selectedSeason !== sessionActiveYear) {
            console.log('reset season in menu list', selectedSeason, sessionActiveYear);
            setSelectedSeason(sessionActiveYear);
            setMenuItemsSet(false);
            setUpdateAttempts(0);
        }
    });

    if (!navItemsForMenu) {
        return null;
    } else {
        return <>{navItemsForMenu}</>;
    }
};

export default MenuList;
