// material-ui
import { Grid } from '@mui/material';

import './statsPage.css';
import StatsPageWrapper from './StatsPageWrapper';

import StatsPageIndexTable from './tables/statsPageIndexTable';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const StatsPageIndex = ({data}) => {
    return (
        <StatsPageWrapper>
            <HelmetProvider>
                <Helmet>
                    <title>2023 NFL Fantasy Stats | Fantasy Upgrade</title>
                    <meta name="title" content="2023 Fantasy Football Stats" />
                    <meta name="description" content='The top 50 players for the 2023 NFL season were: 1) Josh Allen, QB, Buf 2) Jalen Hurts, QB, Phi, 3) Dak Prescott, QB, Dal' />
                    <meta name="keywords" content='Fantasy Football, 2023, NFL, Top 50 Players' />
                </Helmet>
            </HelmetProvider>
            <Grid item xs={12} sx={{ px: 3, py: 2, backgroundColor: '#fafafa' }}>
                <Grid container direction="column">
                    <Grid item xs={12} sx={{ justifyContent: 'center' }}>
                        <h1 style={{ textAlign: 'center', fontSize: '20px', margin: 0 }}>
                            Top 200 Fantasy Football Players in 2023
                        </h1>
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent: 'center', pt: 1, pb: 0 }}>
                        <h2 style={{ textAlign: 'center', fontSize: '18px', margin: 0 }}>
                            Offensive Players
                        </h2>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
                <StatsPageIndexTable data={data} leadingPath={'/nfl/2023'}/>
            </Grid>
        </StatsPageWrapper>
    )
};

export default StatsPageIndex;
