import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import LandingLayout from 'layout/LandingLayout';
// import PricingPage from 'views/pages/landing/pricing';
// import HelpPage from 'views/pages/landing/help';
// import PrivacyPolicyPage from 'views/pages/landing/legal/privacyPolicy';
// import TermsOfUsePage from 'views/pages/landing/legal/termsOfUse';

// routing
const Landing = Loadable(lazy(() => import('views/pages/landing')));
const LandingLayout = Loadable(lazy(() => import('layout/LandingLayout')));
const PricingPage = Loadable(lazy(() => import('views/pages/landing/pricing')));
const HelpPage = Loadable(lazy(() => import('views/pages/landing/help')));
const PrivacyPolicyPage = Loadable(lazy(() => import('views/pages/landing/legal/privacyPolicy')));
const TermsOfUsePage = Loadable(lazy(() => import('views/pages/landing/legal/termsOfUse')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LandingRoutes = {
    path: '/',
    element: <LandingLayout />,
    children: [
        {
            path: '/features',
            element: <Landing />
        },
        {
            path: '/pricing',
            element: <PricingPage />
        },
        {
            path: '/help',
            element: <HelpPage />
        },
        {
            path: '/legal/privacy-policy',
            element: <PrivacyPolicyPage />
        },
        {
            path: '/legal/terms-of-use',
            element: <TermsOfUsePage />
        }
    ]
};

export default LandingRoutes;
