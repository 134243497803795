import { lazy, useRef } from 'react';
// third-party
// import Loadable from 'ui-component/Loadable';
import { Box, Grid, /* Typography */ } from '@mui/material';
import useIsVisible from 'views/utilities/useIsVisible';
// import Chart from 'react-apexcharts';
// const Chart = Loadable(lazy(() => import('react-apexcharts')));
const Chart = lazy(() => import('react-apexcharts'));

// import LoadableFallback from 'ui-component/LoadableFallback';
// const fallbackComp = <div style={{ width: '100%', height: '389px' }}></div>;
// const Chart = LoadableFallback(lazy(() => import('react-apexcharts')), fallbackComp);
// const StatsPointsByGameChart = LoadableFallback(lazy(() => import('./charts/StatsPointsByGameChart')), );

function formatDataForChart(data) {
    /*
        Array of Objects
        Attributes needed:
            week
            points
            location
            opponent

        Series
            name: Points Rank
            data: 
                rank
    */

    const seriesData = [];
    const pointsData = { name: 'All Games', data: [] };
    const homeData = { name: 'Home', data: []};
    const awayData = { name: 'Away', data: []};
    const weekLabels = [];
    const locations = [];
    const opponents = [];
    const colors = [];
    let maxPoints = -10;

    const sortedByWeek = Object.keys(data).sort().reduce(function (result, key) {
        result[key] = data[key];
        return result;
    }, {});

    for (const [key, value] of Object.entries(sortedByWeek)) {
        pointsData.data.push(value.points);
        weekLabels.push(key);
        if (value.points > maxPoints) {
            maxPoints = value.points;
        }
        locations.push(value.location);
        if (value.location === 'Away') {
            homeData.data.push(null);
            awayData.data.push(value.points);
        } else if (value.location === 'Home') {
            homeData.data.push(value.points);
            awayData.data.push(null);
        } else {
            homeData.data.push(null);
            awayData.data.push(null);
        }
        opponents.push(value.opponent)
    }
    seriesData.push(pointsData);
    seriesData.push(homeData);
    seriesData.push(awayData);
    return {
        seriesData,
        weekLabels,
        maxPoints,
        locations,
        opponents,
        colors
    };
}

function getChartOptions(data, totalGames, teamColor, secondaryColor) {
    const options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        colors: ['#7e7e7e', teamColor, secondaryColor],
        dataLabels: {
            enabled: true,
            // style: {
            //     colors: ['#1A73E8', '#B32824']
            // },
            // dropShadow: {
            //     enabled: true,
            //     top: 0,
            //     left: 0,
            //     blur: 1,
            //     color: '#000',
            //     opacity: 1
            // }
        },
        legend: {
            show: true,
            position: 'top'
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: [1,2],
            x: {
                show: true,
                formatter: (val, opts) => {
                    return [`Week ${data.weekLabels[opts.dataPointIndex]} - `, `${data.opponents[opts.dataPointIndex]}`];
                }
            },
            y: {
                show: true,
                hideEmptySeries: true,
                formatter: (val, opts) => {
                    // return data.seriesData[0].data[opts.dataPointIndex];
                    let selectedData = data.seriesData[opts.seriesIndex].data[opts.dataPointIndex];
                    if (selectedData) {
                        return `${selectedData} points`;
                    } else {
                        return null;
                    }
                }
            },
            z: {
                show: true,
                formatter: (val, opts) => {
                    return 'Hello'
                }
            }
        },
        markers: {
            size: 1
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            row: {
                opacity: 0.5
            }
        },
        xaxis: {
            categories: data.weekLabels,
            min: 1,
            max: data.weekLabels.length,
            title: {
                text: 'Week Number'
            },
            labels: {
                show: true
            }
        },
        yaxis: {
            title: {
                text: 'Points'
            },
            reversed: false,
            min: 0,
            forceNiceScale: true,
            tickAmount: 6,
            decimalsInFloat: 0
        },
    };
    return options;
}

const subtitleStyle = {
    // fontWeight: 'bold',
    fontSize: '14px',
    color: '#616161',
    margin: 0,
    padding: '10px 5px 0px 5px',
    textAlign: 'center'
}

const StatsPointsByGameChart = ({data, teamColor, secondaryColor}) => {
    const totalGames = Object.keys(data).length;
    const formattedData = formatDataForChart(data);
    const seriesData = formattedData.seriesData;
    if (secondaryColor === '#FFFFFF') {
        secondaryColor = '#000';
    }
    const chartOptions = getChartOptions(formattedData, totalGames, teamColor, secondaryColor);

    const h2ComponentStyle = {
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: '#212121',
        margin: 0,
        textAlign: 'center',
        backgroundColor: teamColor,
        color: '#f2f2f2',
        outline: `3px solid ${secondaryColor}`,
        padding: '10px 0px 10px 0px',
    };

    const elemRef = useRef();
    const isVisible = useIsVisible(elemRef);
    console.log('isVisible: ', isVisible);

    return (
        <Grid item xs={12} sx={{ px: 0, py: 5, backgroundColor: '#fafafa', minHeight: '525px', minWidth: '100%' }} ref={elemRef}>
            <div style={{ display: (isVisible ? 'block' : 'none') }}>
                <Box sx={{ minWidth: '100%', minHeight: '445px' }}>
                    <h2 style={h2ComponentStyle}>Points by Week</h2>
                    <p style={subtitleStyle}>Hover over the data to see more information</p>
                    <p style={subtitleStyle}>Click on All Games, Home, or Away to toggle that data on the chart</p>
                    <Box id="player-points-chart" sx={{ pt: 3, px: '5px' }}>
                        <Chart options={chartOptions} series={seriesData} type="line" height={350} />
                    </Box>
                </Box>
            </div>
        </Grid>
    )

    // return (
    //     <Box sx={{ minWidth: '100%', minHeight: '445px' }}>
    //         <h2 style={h2ComponentStyle}>Points by Week</h2>
    //         <p style={subtitleStyle}>Hover over the data to see more information</p>
    //         <p style={subtitleStyle}>Click on All Games, Home, or Away to toggle that data on the chart</p>
    //         <Box id="player-points-chart" sx={{ pt: 3, px: '5px' }}>
    //             <Chart options={chartOptions} series={seriesData} type="line" height={350} />
    //         </Box>
    //     </Box>
    // );
};

export default StatsPointsByGameChart;
