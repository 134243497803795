// action - state management
import * as actionTypes from './actions';

const JSON5 = require('json5');

export const initialState = {
    leagues: [],
    activeLeague: null,
    activeYearWeek: null,
    activeWeekData: null,
    maxYearWeek: null,
    selectedYearWeek: null,
    selectedReportTeamID: null,
    selectedLeagueReport: null,
    isMobileDevice: false,
    checkedForMobileDevice: false,
    shouldHideTeamReportsMenu: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const sessionReducer = (state = initialState, action) => {
    console.log(action.type);
    switch (action.type) {
        case actionTypes.SET_SHOULD_HIDE_TEAM_REPORTS_MENU: {
            return {
                ...state,
                shouldHideTeamReportsMenu: action.shouldHideTeamReportsMenu
            }
        }
        case actionTypes.SET_LEAGUES: {
            const sortedLeagues = action.leagues.sort((a, b) => (a.name > b.name ? 1 : -1));
            return {
                ...state,
                leagues: sortedLeagues
            };
        }
        case actionTypes.SET_ACTIVE_LEAGUE_IF_NEEDED: {
            if (state.activeLeague.id === action.activeLeague.id) {
                return state;
            }
            return state;
        }
        case actionTypes.SET_ACTIVE_LEAGUE:
            return {
                ...state,
                activeLeague: action.activeLeague,
                activeYearWeek: action.activeLeague.lastUpdate,
                maxYearWeek: action.activeLeague.lastUpdate
            };
        case actionTypes.SET_SELECTED_YEAR_WEEK:
            return {
                ...state,
                selectedYearWeek: action.selectedYearWeek
            };
        case actionTypes.SET_SELECTED_REPORT_TEAM_ID:
            console.log('SET SELECTED REPORT TEAM ID');
            return {
                ...state,
                selectedReportTeamID: action.selectedReportTeamID
            };
        case actionTypes.SET_ACTIVE_WEEK_DATA:
            return {
                ...state,
                activeWeekData: action.activeWeekData
            };
        case actionTypes.SET_ACTIVE_YEAR_WEEK:
            return {
                ...state,
                activeYearWeek: action.activeYearWeek
            };
        case actionTypes.SET_ACTIVE_YEAR_WEEK_AND_DATA:
            if (JSON.stringify(state.activeYearWeek) === JSON.stringify(action.activeYearWeek)) {
                return {
                    ...state
                };
            }
            if (JSON.stringify(state.activeYearWeek) === JSON.stringify(action.selectedYearWeek)) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                activeYearWeek: action.activeYearWeek,
                activeWeekData: action.activeWeekData,
                selectedYearWeek: action.selectedYearWeek
            };
        case actionTypes.SET_MAX_YEAR_WEEK:
            return {
                ...state,
                maxYearWeek: action.maxYearWeek
            };
        case actionTypes.SET_SELECTED_LEAGUE_REPORT: {
            return {
                ...state,
                selectedLeagueReport: action.selectedLeagueReport
            };
        }
        case actionTypes.SET_IS_MOBILE_DEVICE: {
            return {
                ...state,
                isMobileDevice: action.isMobileDevice,
                checkedForMobileDevice: true
            };
        }
        default:
            return state;
    }
};

export default sessionReducer;
