import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// import Amplify from 'aws-amplify';
// import { Amplify, API } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
// import { AmplifyProvider } from '@aws-amplify/ui-react';

// style + assets
import 'assets/scss/style.scss';
// import '@aws-amplify/ui-react/styles.css';
// Loadable(lazy(() => import('@aws-amplify/ui-react/styles.css')));
import './themes/loginStyle.css';

import './views/login-signup/login-signup.css';


Amplify.configure(awsconfig);
// API.configure();
// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
