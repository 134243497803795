import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
// import HomePage from 'views/pages/landing/home';

const HomePage = Loadable(lazy(() => import('views/pages/landing/home')));

// routing

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const HomeRoutes = {
    path: '/',
    element: <HomePage />
};

export default HomeRoutes;
