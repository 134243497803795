import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, IconButton, MenuItem, Tab, Tabs, Typography } from '@mui/material';

// project imports
import LogoSection from 'layout/MainLayout/LogoSection';
// import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import './LandingHeader.css';

// assets
import { IconMenu2 } from '@tabler/icons';
import SearchSection from 'layout/MainLayout/Header/SearchSection';
import ProfileSection from 'layout/MainLayout/Header/ProfileSection';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const pages = ['Features', 'Pricing', 'Leagues', 'Help', 'Invitation', 'Stats'];

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const LandingHeader = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleNavItemSelected = (value) => {
        console.log(value);
        handleCloseNavMenu();
        handleChange(null, value);
    }
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // const [selectedTab, setSelectedTab] = useState('Leagues');
    const [selectedTab, setSelectedTab] = useState('Features');
    useEffect(() => {
        const currentURL = window.location.href;
        if (currentURL.indexOf('/pricing') !== -1) {
            setSelectedTab('Pricing');
        } else if (currentURL.indexOf('/features') !== -1) {
            setSelectedTab('Features');
        } else if (currentURL.indexOf('/leagues') !== -1) {
            setSelectedTab('Leagues');
        } else if (currentURL.indexOf('/help') !== -1) {
            setSelectedTab('Help');
        } else if (currentURL.indexOf('/invitation') !== -1) {
            setSelectedTab('Invitation');
        } else {
            setSelectedTab('');
        }
    });

    const handleChange = (event, newValue) => {
        if (newValue === 'Leagues') {
            navigate('/leagues');
        } else if (newValue === 'Features') {
            navigate('/features');
        } else if (newValue === 'Pricing') {
            navigate('/pricing');
        } else if (newValue === 'Help') {
            navigate('/help');
        } else if (newValue === 'Invitation') {
            navigate('/invitation');
        } else if (newValue === 'Stats') {
            navigate('/nfl/2023');
        }
        // setSelectedTab(newValue);
    };

    return (
        <>
            {/* logo */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, filter: 'invert(1)' }}>
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
                <Box className="navigation-tabs">
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Features').toString()}>
                        <Link to="/features" href="/features">Features</Link>
                    </Box>
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Pricing').toString()}>
                        <Link to="/pricing" href="/pricing">Pricing</Link>
                    </Box>
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Leagues').toString()}>
                        <Link to="/leagues" href="/leagues">Leagues</Link>
                    </Box>
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Help').toString()}>
                        <Link to="/help" href="/help">Help</Link>
                    </Box>
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Invitation').toString()}>
                        <Link to="/invitation" href="/invitation">Invitation</Link>
                    </Box>
                    <Box className="navigation-tab-box" is-selected={(selectedTab === 'Stats').toString()}>
                        <Link to="/nfl/2023" href="/nfl/2023">Stats</Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon sx={{ color: '#ececec' }} />
                </IconButton>
                <Box component="span" sx={{ display: 'flex', pointerEvents: 'none', filter: 'invert(1)' }}>
                    <LogoSection />
                </Box>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                >
                    {pages.map((page) => (
                        <MenuItem key={page} onClick={(e) => handleNavItemSelected(page)} value={page}>
                            <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            <ProfileSection />
        </>
    );
};

LandingHeader.propTypes = {};

export default LandingHeader;
