import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

// project imports
// import DemoLayout from 'layout/MainLayout/demoLayout';
// import LeagueReportSeason from 'views/dashboard/League-Report/LeagueReportSeason';
// import LeagueReportWeek from 'views/dashboard/League-Report/LeagueReportWeek';
// import TeamReportSeason from 'views/dashboard/Team-Report/TeamReportSeason';

const DemoLayout = Loadable(lazy(() => import('layout/MainLayout/demoLayout')));
const LeagueReportSeason = Loadable(lazy(() => import('views/dashboard/League-Report/LeagueReportSeason')));
const LeagueReportWeek = Loadable(lazy(() => import('views/dashboard/League-Report/LeagueReportWeek')));
const TeamReportSeason = Loadable(lazy(() => import('views/dashboard/Team-Report/TeamReportSeason')));

// ==============================|| MAIN ROUTING ||============================== //

const DemoRoutes = {
    path: '/dashboard',
    element: <DemoLayout />,
    children: [
        {
            path: '/demo/league/report/season',
            element: <LeagueReportSeason isSeasonReport={true} isWeeklyReport={false} />
        },
        {
            path: '/demo/league/report/week',
            element: <LeagueReportWeek isSeasonReport={false} isWeeklyReport={true} />
        },
        {
            path: '/demo/weekly/report/manager/*',
            element: <TeamReportSeason />
        },
    ]
};

export default DemoRoutes;
