import StatsPage from "views/pages/stats/StatsPage";
import StatsPageIndex from "views/pages/stats/StatsPageIndex";

import nflTop200 from './player-stats-index.json';

// import { default as player } from './player-results/josh-allen-2023-fantasy.js';

/*
    #1 Josh Allen - Quarterback, Buffalo Bills		392.64 Pts	

    17 Games Played
    4306 Pass Yards
    29 Pass TDs
    18 INts 
    524 Rush Yards
    15 Rush Tds
    23.1 Fantasy Points Per Game
    392.64 Total Fantasy Points


    Josh Allen was the #1 overall fantasy football player for the 2023 NFL Season.
    He ranked #1 for Quarterbacks and was the #1 fantasy scorer on the Buffalo Bills.
    He accumulated 4306 Passing Yards, 18 Passing Touchdowns, 524 Rushing Yards and 15 Rushing Touchdowns during his 17 game season.
    He finishes 2023 averaging 23.1 Fantasy Points per game with 392.64 Fantasy Points Total. 
*/

export function createFantasyStatsPages() {
    // console.log(nflTop200);
    // console.log(player);
    // console.log(player);
    // nflTop200.unshift(player);
    

    let indexElements = [];
    let elements = [];
    for (let i = 0; i < nflTop200.length; i++) {
        let selected = nflTop200[i];
        elements.push({
            path: selected.extension,
            element: <StatsPage data={selected.player} extension={selected.extension} />
        })
        indexElements.push({
            path: selected.extension,
            player: {
                name: selected.player.name,
                pos: selected.player.pos,
                team: selected.player.team,
                // points: selected.player.stats.details.Points,
                points: selected.player.points,
                overallRank: selected.player.overallRank,
                positionRank: selected.player.positionRank
            }
        })
    }
    let indexPage = <StatsPageIndex data={indexElements} />
    elements.push({
        path: '/',
        element: indexPage
    });
    return elements;
}

async function loadPlayersData() {
    let nflTop200Players2023 = fetch(raw)
    .then(r => r.text())
    .then(text => {
        console.log(text);
        let jsonString = JSON.parse(text);
        console.log(jsonString);
        return jsonString;
    });
    return nflTop200Players2023;
}