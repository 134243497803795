// assets
import { IconDashboard, IconNotes, IconChartBar, IconChartDots, IconStars, IconListNumbers, IconReportAnalytics } from '@tabler/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_REPORT_TEAM_ID } from 'store/actions';

// constant
const icons = { IconDashboard, IconReportAnalytics, IconNotes, IconChartBar, IconChartDots, IconStars, IconListNumbers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

/* target: true -> opens a new tab */

// const getTeamReportMenuItems = () => {
export function getTeamReportMenuItems(managersByTeamID) {
    // console.log(managersByTeamID);

    // useEffect(() => {
    //     console.log(selectedLeagueReport);
    //     const selectedLeagueReport = useSelector((state) => state.session.selectedLeagueReport);
    // });
    // const dispatch = useDispatch();
    // console.log('getTeamReportMenuItems');
    // const leagueStandings = getMockReportLeagueStandings();
    let sortedStandings = {};
    const standingsArray = [];
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(managersByTeamID)) {
        standingsArray.push({
            teamID: key,
            ...value
        });
    }
    sortedStandings = standingsArray.sort((a, b) => {
        const managerA = a.manager.toUpperCase();
        const managerB = b.manager.toUpperCase();
        return managerA < managerB ? -1 : 1;
    });
    const teamReportMenuItems = [];
    // eslint-disable-next-line
    for (let i = 0; i < sortedStandings.length; i++) {
        teamReportMenuItems.push({
            id: `team-report-${sortedStandings[i].teamID}`,
            title: sortedStandings[i].manager,
            type: 'item',
            url: `/dashboard/season/report/team/${sortedStandings[i].teamID}`,
            breadcrumbs: false
        });
    }

    const menuItems = [
        {
            id: 'leagueReport',
            title: 'League Report',
            type: 'item',
            url: '/dashboard/season/report/league',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        },
        {
            id: 'teamReport',
            title: 'Team Reports',
            type: 'collapse',
            url: '/dashboard/season/report/team',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false,
            children: teamReportMenuItems
        }
    ];
    return menuItems;
};

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    caption: 'Data for this season!',
    type: 'group',
    // children: getTeamReportMenuItems()
    // children: [
    // {
    //     id: 'default',
    //     title: 'Dashboard',
    //     type: 'item',
    //     url: '/dashboard/season',
    //     icon: icons.IconDashboard,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'leagueReport',
    //     title: 'League Report',
    //     type: 'item',
    //     url: '/dashboard/season/report/league',
    //     icon: icons.IconReportAnalytics,
    //     breadcrumbs: false
    // },
    // { getTeamReportMenuItems() }
    // {
    //     id: 'teamReport',
    //     title: 'Team Report',
    //     type: 'item',
    //     url: '/dashboard/season/report/team',
    //     icon: icons.IconReportAnalytics,
    //     breadcrumbs: false
    // }
    // {
    //     id: 'leagueNotes',
    //     title: 'League Notes',
    //     type: 'item',
    //     url: '/dashboard/season/league-notes',
    //     icon: icons.IconNotes,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'stats',
    //     title: 'Stats',
    //     type: 'collapse',
    //     icon: icons.IconChartBar,
    //     breadcrumbs: false,

    //     children: [
    //         {
    //             id: 'teamStats',
    //             title: 'Team Stats',
    //             type: 'item',
    //             url: '/dashboard/season/stats/team',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'playerStats',
    //             title: 'Player Stats',
    //             type: 'item',
    //             url: '/dashboard/season/stats/player',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'playerStudsAndDuds',
    //             title: 'Studs and Duds',
    //             type: 'item',
    //             url: '/dashboard/season/stats/studs-and-duds',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'powerRankings',
    //     title: 'Power Rankings',
    //     type: 'item',
    //     url: '/dashboard/season/power-rankings',
    //     icon: icons.IconListNumbers,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'teamsOfTheWeek',
    //     title: 'Teams of the Week',
    //     type: 'item',
    //     url: '/dashboard/season/teams-of-the-week',
    //     icon: icons.IconStars,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'matchupPreviews',
    //     title: 'Matchup Previews',
    //     type: 'item',
    //     url: '/dashboard/season/matchup-previews',
    //     icon: icons.IconChartDots,
    //     breadcrumbs: false
    // }
    // ]
};

export default dashboard;
