import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// import { withAuthenticator } from '@aws-amplify/ui-react';

// routing
import Routes from 'routes';
import React from 'react';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    // const dispatch = useDispatch();

    // useEffect(async () => {
    // }, [dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <HelmetProvider>
                <Helmet>
                    <title>Fantasy Upgrade</title>
                    <meta name="title" content="Fantasy Upgrade" />
                    <meta name="description" content="Make your fantasy league more fun with detailed statistics of the best and worst teams in your league!" />
                </Helmet>
            </HelmetProvider>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
// export default withAuthenticator(App);
