// Functions to help with the organization and data structuring for stats pages.

export function convertNFLTeamToColorKey(team) {
    switch (team) {
        case 'Arizona Cardinals': {
            return ['ariPrimary', 'ariSecondary'];
        }
        case 'Atlanta Falcons': {
            return ['atlPrimary', 'atlSecondary'];
        }
        case 'Baltimore Ravens': {
            return ['balPrimary', 'balSecondary'];
        }
        case 'Buffalo Bills': {
            return ['bufPrimary', 'bufSecondary'];
        }
        case 'Carolina Panthers': {
            return ['carPrimary', 'carSecondary'];
        }
        case 'Chicago Bears': {
            return ['chiPrimary', 'chiSecondary'];
        }
        case 'Cincinnati Bengals': {
            return ['cinPrimary', 'cinSecondary'];
        }
        case 'Cleveland Browns': {
            return ['clePrimary', 'cleSecondary'];
        }
        case 'Dallas Cowboys': {
            return ['dalPrimary', 'dalSecondary'];
        }
        case 'Denver Broncos': {
            return ['denPrimary', 'denSecondary'];
        }
        case 'Detroit Lions': {
            return ['detPrimary', 'detSecondary'];
        }
        case 'Green Bay Packers': {
            return ['gbPrimary', 'gbSecondary'];
        }
        case 'Houston Texans': {
            return ['houPrimary', 'houSecondary'];
        }
        case 'Indianapolis Colts': {
            return ['indPrimary', 'indSecondary'];
        }
        case 'Jacksonville Jaguars': {
            return ['jaxPrimary', 'jaxSecondary'];
        }
        case 'Kansas City Chiefs': {
            return ['kcPrimary', 'kcSecondary'];
        }
        case 'Las Vegas Raiders': {
            return ['lvPrimary', 'lvSecondary'];
        }
        case 'Oakland Raiders': {
            return ['lvPrimary', 'lvSecondary'];
        }
        case 'Los Angeles Chargers': {
            return ['lacPrimary', 'lacSecondary'];
        }
        case 'Los Angeles Rams': {
            return ['larPrimary', 'larSecondary'];
        }
        case 'Miami Dolphins': {
            return ['miaPrimary', 'miaSecondary'];
        }
        case 'Minnesota Vikings': {
            return ['minPrimary', 'minSecondary'];
        }
        case 'New England Patriots': {
            return ['nePrimary', 'neSecondary'];
        }
        case 'New Orleans Saints': {
            return ['noPrimary', 'noSecondary'];
        }
        case 'New York Giants': {
            return ['nygPrimary', 'nygSecondary'];
        }
        case 'New York Jets': {
            return ['nyjPrimary', 'nyjSecondary'];
        }
        case 'Philadelphia Eagles': {
            return ['phiPrimary', 'phiSecondary']
        }
        case 'Pittsburgh Steelers': {
            return ['pitPrimary', 'pitSecondary'];
        }
        case 'San Francisco 49ers': {
            return ['sfPrimary', 'sfSecondary'];
        }
        case 'Seattle Seahawks': {
            return ['seaPrimary', 'seaSecondary'];
        }
        case 'Tampa Bay Buccaneers': {
            return ['tbPrimary', 'tbSecondary'];
        }
        case 'Tennessee Titans': {
            return ['tenPrimary', 'tenSecondary'];
        }
        case 'Washington Commanders': {
            return ['wshPrimary', 'wshSecondary'];
        }
    }
}


export function convertStatToStandardPoints(name, value) {
    switch (name) {
        case 'Pass Yards': {
            return parseFloat((value * 0.04).toFixed(2));
        }
        case 'Pass TDs': {
            return (value * 4);
        }
        case 'Ints': {
            return (value * -2);
        }
        case 'Fumbles Lost': {
            return (value * -2);
        }
        case 'Rush Yards': {
            return parseFloat((value * 0.1).toFixed(2));
        }
        case 'Rush TDs': {
            return (value * 6);
        }
        case 'Receiving Yards': {
            return parseFloat((value * 0.1).toFixed(2));
        }
        case 'Receiving TDs': {
            return (value * 6);
        }
        default: {
            return 0;
            break;
        }
    }
}